import { ref } from 'vue';

import type { IDataTableBaseCell, IDataTableSettingsActions } from '~/types';
export const stickyCols = ref<HTMLElement[]>([]);

export const setStickyCols = (area: HTMLElement) => {
  stickyCols.value = [
    ...area.getElementsByClassName<HTMLElement>('header sticky'),
  ] as HTMLElement[];
};

export const getStickyActionsWidth = (
  actions: IDataTableSettingsActions,
): number => {
  const pxPerAction = 50;
  const minPx = 98;

  let total = 0;
  for (const a of actions()) {
    total = (a.width ?? pxPerAction) + total;
  }

  return Math.max(minPx, total);
};

const getRemainingStickyCols = (
  parentColumns: IDataTableBaseCell[],
  index: number,
) => {
  return parentColumns.filter(
    ({ position }, idx) => idx > index && position === 'sticky',
  );
};

export const getStickyColumnOffset = (
  key: string,
  parentColumns: IDataTableBaseCell[],
  actions?: IDataTableSettingsActions,
) => {
  const index = parentColumns.findIndex((col) => col.key === key);
  const isStickyColumn = parentColumns[index].position === 'sticky';

  if (!isStickyColumn) {
    return undefined;
  }

  const isLastColumn = index + 1 === parentColumns.length;
  let offset = actions ? getStickyActionsWidth(actions) : 0;
  if (!isLastColumn) {
    const remainingStickyCols = getRemainingStickyCols(parentColumns, index);
    const rightOffset = remainingStickyCols.reduce((acc, current, idx) => {
      const currentPosition = idx + 1;
      const currentOffset =
        stickyCols.value?.[stickyCols.value.length - currentPosition]
          ?.offsetWidth ??
        parentColumns[parentColumns.length - currentPosition].width;
      return acc + currentOffset;
    }, 0);
    offset += rightOffset;
  }

  return offset;
};

export const hasStickyColumns = (columns: IDataTableBaseCell[]): boolean =>
  columns.some((col) => col.position === 'sticky');

export const isFirstStickyColumn = (
  columns: IDataTableBaseCell[],
  key: string,
) => {
  const colIndex = columns.findIndex((col) => col.key === key);
  const column = columns[colIndex];

  if (column.position !== 'sticky') {
    return false;
  }

  return columns[colIndex - 1]?.position !== 'sticky';
};
