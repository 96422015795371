import { h } from 'vue';

import { USER_TYPES } from '~/constants';

import { capitalize } from '~/features/useUtilities';

import UserListPendingUserInfo from '~/components/UserListPendingUserInfo.vue';
import UserListUserDetails from '~/components/UserListUserDetails.vue';
import UserListUserTypeTag from '~/components/UserListUserTypeTag.vue';

import type { IAdminUserListUsersTableRow, IProfileType } from '~/types';

export const useUserDataTable = () => {
  const userColumns = {
    details: {
      header: 'User Details',
      key: 'details',
      visible: true,
      render: (item?: IAdminUserListUsersTableRow) => {
        return item?.email_verified
          ? h(UserListUserDetails, {
              firstName: item?.first_name,
              lastName: item?.last_name,
            })
          : UserListPendingUserInfo;
      },
    },
    organisations: {
      header: 'Organisation',
      key: 'organisations',
      visible: true,
      transform: (item: IAdminUserListUsersTableRow) => {
        const isInternal = item?.user_type === USER_TYPES.G2O;

        if (isInternal) {
          return null;
        }

        if (item.has_all_organisations) return 'All Organisations';
        const organisationsLength = item.organisations.length;

        if (!organisationsLength) return 'No Organisations';

        const [organisation] = item.organisations;

        return organisationsLength > 1
          ? `${organisation} (+${organisationsLength - 1})`
          : organisation;
      },
    },
    email: {
      header: 'User Email',
      key: 'email',
    },
    user_type: {
      header: 'User Type',
      key: 'user_type',
      render: () => UserListUserTypeTag,
    },
    role: {
      header: 'User Role',
      key: 'role',
      valueFormatter: (value: string) => capitalize(value),
    },
    business_type: {
      header: `Company's Business`,
      key: 'business_type',
    },
  };

  const getUserTypeState = (user_type?: IProfileType) => {
    const isInternal = user_type === USER_TYPES.G2O;
    const isPublicUser = user_type === USER_TYPES.PUBLIC;
    const isCustomerUser = user_type === USER_TYPES.CUSTOMER;

    return {
      isInternal,
      isPublicUser,
      isCustomerUser,
    };
  };

  const geActionsTooltipText = (
    user_type?: IProfileType | null,
    isCurrentUser?: boolean,
  ) => {
    const USER_TYPES_TO_EDIT_TOOLTIP = {
      [USER_TYPES.G2O]: 'G2O users can be edited on the User List.',
      [USER_TYPES.PUBLIC]: 'Public users cannot be edited.',
      [USER_TYPES.CUSTOMER]:
        'Please select assigned User’s organisation on the left side panel in order to edit this User.',
    };

    const removeTooltip = isCurrentUser
      ? 'To remove this user, navigate to My Details.'
      : 'Remove';

    const editTooltip = isCurrentUser
      ? 'To edit this user, navigate to My Details.'
      : ((user_type && USER_TYPES_TO_EDIT_TOOLTIP[user_type]) ?? 'Edit');

    return { removeTooltip, editTooltip };
  };

  return { userColumns, geActionsTooltipText, getUserTypeState };
};
