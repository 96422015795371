<script lang="ts" setup name="UserListUserDetails">
import { computed } from 'vue';

import { getFullName } from '~/features/useUtilities';

import Avatar from '~/components/Avatar.vue';

const { firstName = null, lastName = null } = defineProps<{
  firstName?: string;
  lastName?: string;
}>();

const fullName = computed(() => getFullName(firstName, lastName));
</script>

<template>
  <div class="flex items-center">
    <Avatar size="lg" :name="fullName" />
    <p class="pl-4 text-body-1">{{ fullName }}</p>
  </div>
</template>
