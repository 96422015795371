<script setup lang="ts" name="DataTableHeaderCell">
import { computed, ref } from 'vue';

import { isFirstStickyColumn } from '~/features/useDataTableStickyColumn';

import SortIcon from '~/components/SortIcon.vue';

import type {
  IDataTableBaseCell,
  IDataTablePaddingType,
  IDataTableSettingsActions,
  SortDirection,
} from '~/types';

const { column, columns, actions } = defineProps<{
  column: IDataTableBaseCell;
  columns: IDataTableBaseCell[];
  areaOverflows: boolean;
  isFirstCell: boolean;
  isLastCell: boolean;
  padding: IDataTablePaddingType;
  actions?: IDataTableSettingsActions;
  rightOffset?: number;
}>();

const emit = defineEmits<{
  onSort: [column: IDataTableBaseCell, direction: SortDirection];
}>();

const direction = ref<SortDirection>(column.sortDirection ?? null);

const isSticky = computed(() => column.position === 'sticky');

const isFirstSticky = computed(() => isFirstStickyColumn(columns, column.key));

const callSort = () => {
  let newDirection: SortDirection = null;
  if (!direction.value && column.allowedSortDirection !== 'desc') {
    newDirection = 'asc';
  }

  if (direction.value === 'asc' && column.allowedSortDirection !== 'asc') {
    newDirection = 'desc';
  }

  direction.value = newDirection;
  emit('onSort', column, newDirection);
  return;
};
</script>

<template>
  <div
    role="columnheader"
    class="p-4 font-bold"
    :class="{
      'pl-3 pr-4': padding === 'small' && isFirstCell,
      'pl-4 pr-3': padding === 'small' && isLastCell,
      'pl-6 pr-4': padding === 'medium' && isFirstCell,
      'pl-4 pr-6': padding === 'medium' && isLastCell,
      'text-left': column.align === 'left',
      'text-right': column.align === 'right',
      'header sticky top-0 bg-charcoal-2': isSticky,
      'cargo-booking-sticky-shadow border-l border-l-charcoal-2':
        isFirstSticky && areaOverflows,
    }"
    :style="{
      right: `${rightOffset}px`,
    }"
  >
    <span :class="{ 'flex items-center': column.sortable }">
      {{ column.header }}
      <SortIcon
        v-if="column.sortable"
        class="cursor-pointer"
        :sorted="!!direction"
        :direction="direction"
        @click="callSort"
      />
    </span>
  </div>
</template>
