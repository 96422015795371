<script setup lang="ts" name="DataTable" generic="T extends IDataTableBaseRow">
import type { Ref } from 'vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useResizeObserver, useVModel, watchDebounced } from '@vueuse/core';
import { v4 } from 'uuid';

import { useDataTableData } from '~/features/useDataTableData';
import { useDataTableEventHandlers } from '~/features/useDataTableEventHandlers';
import { useDataTableGridTemplateColumns } from '~/features/useDataTableGridTemplateColumns';
import { useDataTableInputKeywordNavigation } from '~/features/useDataTableInputKeywordNavigation';
import { useDataTableResponsiveColumns } from '~/features/useDataTableResponsiveColumns';
import { useDataTableRowUpdates } from '~/features/useDataTableRowUpdates';
import { useDataTableSelectedCell } from '~/features/useDataTableSelectedCell';
import { useDataTableStepCells } from '~/features/useDataTableStepCells';
import { setStickyCols } from '~/features/useDataTableStickyColumn';

import BaseAutoPagination from '~/components/BaseAutoPagination.vue';
import DataTableHeader from '~/components/DataTableHeader.vue';
import DataTableNoResults from '~/components/DataTableNoResults.vue';
import DataTableResponsiveRow from '~/components/DataTableResponsiveRow.vue';
import DataTableRows from '~/components/DataTableRows.vue';
import SearchField from '~/components/SearchField.vue';

import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableRow,
  IDataTableSettings,
  SortDirection,
} from '~/types';

const props = withDefaults(
  defineProps<{
    data: T[];
    settings: IDataTableSettings<T>;
    selectedTableRows?: T[];
    cellAddress?: string;
    subgrid?: boolean;
    search?: string;
    overflowContainer?: HTMLElement;
    noResultsText?: string;
  }>(),
  {
    selectedTableRows: () => [],
    noResultsText: 'No records found based on your search criteria.',
  },
);
const emit = defineEmits<{
  onRowUpdate: [data: T];
  areaOverflows: [value: boolean, overflowAreaWidth: number];
  sort: [key: string, direction: SortDirection];
  'update:cellAddress': [address: string];
  'update:selectedTableRows': [value: T[]];
  'update:search': [string];
}>();

const uniqueTableId = v4();
const getTableId = () => uniqueTableId;

const tableData = computed(() => props.data);

const tableGrid = ref<HTMLElement>();
const scrollWidth = ref<number>(0);
const areaOverflows = ref(false);
const overflowAreaWidth = ref<number | null>(null);
const overflowArea = ref<HTMLElement>();
const overflowContainer = ref<HTMLElement | undefined>(props.overflowContainer);

const selectedCellAddress =
  props.cellAddress === undefined
    ? ref('')
    : useVModel(props, 'cellAddress', emit);

const selectedRows = useVModel(props, 'selectedTableRows', emit);

const addressManager = useDataTableSelectedCell({
  getTableId,
  selectedCellAddress,
});

const {
  tableRows,
  getNumberOfCellsInRow,
  getTableLength,
  toggleEditMode,
  resetEditMode,
  updateCellValue,
  getRowByIndex,
} = useDataTableData({
  addressManager,
  getErrorMessage: props.settings.getErrorMessage,
  data: tableData,
  columns: computed(() => props.settings.columns),
});

const {
  responsiveTableSettings,
  responsiveTableRows,
  responsiveCollapsibleTableRows,
  hasVisibleColumns,
  removeCollapsibleColumn,
  addCollapsibleColumn,
} = useDataTableResponsiveColumns(
  computed(() => props.settings),
  tableData,
  tableRows,
);

const tableSettings = computed<IDataTableSettings<T>>(() => {
  const settings = hasVisibleColumns.value
    ? responsiveTableSettings.value
    : props.settings;

  return {
    ...settings,
    padding: settings.padding ?? 'medium',
  };
});

const dataTableRows = computed<IDataTableRow[]>(() =>
  hasVisibleColumns.value ? responsiveTableRows.value : tableRows.value,
);

const stepCellManager = useDataTableStepCells(
  tableRows,
  addressManager,
  toggleEditMode,
);

const { gridEvents, handleCellClick, handleCellDoubleClick, moveRightOrTab } =
  useDataTableEventHandlers({
    editable: props.settings.editable,
    getTableId,
    addressManager,
    getTableLength,
    toggleEditMode,
    getNumberOfCellsInRow,
    resetStepCells: stepCellManager.resetStepCells,
    isRowCancelled: (rowNumber: number) => tableRows.value[rowNumber].cancelled,
    tableRef: tableGrid,
  });

const { handleInputUpdate, updateRowForStepCells } = useDataTableRowUpdates({
  data: tableData,
  getRowByIndex,
  addressManager,
  updateCellValue,
  stepCellManager,
  onRowUpdate: (newRow: T) => emit('onRowUpdate', newRow),
});

const { handleInputTab, handleInputEnter } = useDataTableInputKeywordNavigation(
  {
    stepCellManager,
    addressManager,
    moveRightOrTab,
    toggleEditMode,
    resetEditMode,
    updateRowForStepCells,
  },
);

const gridTemplateColumns = computed(() =>
  !props.subgrid
    ? useDataTableGridTemplateColumns(tableSettings.value)
    : undefined,
);

const search = useVModel(props, 'search', emit);

const callSort = (column: IDataTableBaseCell, direction: SortDirection) => {
  const key = column.dynamic ?? column.key;
  emit('sort', key, direction);
};

const callResizeObserver = (target?: Ref<HTMLElement | undefined>) => {
  const area = target ?? overflowArea;
  useResizeObserver(area, ([entry]) => {
    if (!area.value || !tableGrid.value) return;

    const { width } = entry.contentRect;
    overflowAreaWidth.value = width;
    areaOverflows.value = overflowAreaWidth.value < tableGrid.value.scrollWidth;
    setStickyCols(area.value);
    emit('areaOverflows', areaOverflows.value, overflowAreaWidth.value);
  });
};

const updateResponsiveValues = () => {
  if (!tableGrid.value || !overflowAreaWidth.value) {
    return;
  }

  scrollWidth.value = tableGrid.value.scrollWidth;
  areaOverflows.value = overflowAreaWidth.value < scrollWidth.value;
};

watch(
  () => props.overflowContainer,
  (newValue) => {
    if (newValue) {
      overflowContainer.value = newValue;
      callResizeObserver(overflowContainer);
    }
  },
);

if (hasVisibleColumns.value) {
  watchDebounced(
    () => [overflowAreaWidth.value, areaOverflows.value, scrollWidth.value],
    async ([newWidth, newAreaOverflows], [oldWidth]) => {
      if (newAreaOverflows) {
        addCollapsibleColumn();
        await nextTick();

        updateResponsiveValues();
      }

      const isWidthIncreased = newWidth && oldWidth && newWidth > oldWidth;

      if (isWidthIncreased && !newAreaOverflows) {
        removeCollapsibleColumn();
        await nextTick();

        updateResponsiveValues();
      }
    },
    {
      debounce: 50,
    },
  );
}

onMounted(() => {
  callResizeObserver();
});
</script>

<template>
  <div class="min-w-0" ref="overflowArea">
    <SearchField
      v-if="settings.searchable"
      v-model="search"
      :placeholder="settings.searchPlaceholder"
    />
    <div
      role="grid"
      tabindex="0"
      ref="tableGrid"
      class="grid outline-none"
      :class="{
        'col-span-full grid-cols-subgrid': subgrid,
        'overflow-x-auto': !subgrid,
      }"
      v-on="gridEvents"
      v-drag-scroll="!subgrid"
      :style="gridTemplateColumns"
    >
      <DataTableHeader
        v-model:selectedTableRows="selectedRows"
        :tableData="tableData"
        :columns="tableSettings.columns"
        :areaOverflows="areaOverflows"
        :checkboxSelection="tableSettings.checkboxSelection"
        :paddingColumn="tableSettings.collapsibleRows"
        :padding="tableSettings.padding"
        :actions="tableSettings.actions"
        @onSort="callSort"
      />
      <slot name="tableRows">
        <DataTableRows
          v-if="!!props.data.length"
          v-model:selectedTableRows="selectedRows"
          :tableData="tableData"
          :tableRows="dataTableRows"
          :settings="tableSettings"
          :areaOverflows="areaOverflows"
          :overflowAreaWidth="overflowAreaWidth"
          :selectedCellAddress="addressManager.selectedCellAddress.value"
          :padding="tableSettings.padding"
          :initializeSteps="stepCellManager.initializeSteps"
          :handleCellClick="handleCellClick"
          :handleCellDoubleClick="handleCellDoubleClick"
          :handleInputUpdate="handleInputUpdate"
          :handleInputTab="handleInputTab"
          :handleInputEnter="handleInputEnter"
        >
          <template #collapsible="props">
            <DataTableResponsiveRow
              v-if="responsiveCollapsibleTableRows.length"
              :row="responsiveCollapsibleTableRows[props.rowIndex]"
              :areaOverflows="areaOverflows"
              :columns="tableSettings.columns"
              :actions="tableSettings.actions"
            />

            <slot v-else name="collapsible" v-bind="props" />
          </template>

          <template #actions="props">
            <slot name="actions" v-bind="props" />
          </template>
        </DataTableRows>
        <div class="col-span-full" v-else>
          <slot name="no-results" :overflowAreaWidth>
            <DataTableNoResults :overflowAreaWidth :noResultsText />
          </slot>
        </div>
      </slot>
    </div>

    <div
      v-if="settings.pagination && !!props.data.length"
      class="sticky left-0 w-full"
    >
      <BaseAutoPagination styled />
    </div>
  </div>
</template>
